import {
  DashboardFlagType,
  dashboardTypeFlag,
  getLaunchDarklyFlagKeyFromReportName,
  landingPageFlag,
} from "../../utility/launchDarklyFlagHelper";
import { useFlags } from "launchdarkly-react-client-sdk";
import UserSettingsService from "../../services/userSettingsService";
import {
  DashboardGroupVisuals,
  KpiDashboardVisual,
} from "../../model/userReportsModel";
import VisualMaximizedDashboardContainer from "../visuals/visualMaximizedDashboardContainer/VisualMaximizedDashboardContainer";
import { ErrorMessages, LoaderMessages } from "../../utility/Messages";
import KpiDashboardContainer from "../visuals/kpiDashboardContainer/KpiDashboardContainer";
import NoAccess from "../controls/noAccess/NoAccess";
import { useEffect, useState } from "react";
import NativeDashboardContainer from "../visuals/nativeDashboardContainer/NativeDashboardContainer";
import NativeDashboardService from "../../services/nativeDashboardService";
import { NativeVisualsDossierGroup } from "../../model/userNativeReportsModel";
import Loader from "../controls/loader/Loader";

function DashboardPage() {
  const launchDarklyFlags = useFlags();

  const [nativeReports, setNativeReports] = useState<
    NativeVisualsDossierGroup[]
  >([]);
  const [nativeReportsLoaded, setNativeReportsLoaded] = useState(false);
  const dashboardType = (launchDarklyFlags[dashboardTypeFlag] ??
    "visualMaximized") as DashboardFlagType;

  useEffect(() => {
    if (dashboardType === "native") {
      NativeDashboardService.reports(launchDarklyFlags).then((nv) => {
        setNativeReports(nv);
        setNativeReportsLoaded(true);
      });
    }
  }, [dashboardType, launchDarklyFlags]);

  if (!launchDarklyFlags[landingPageFlag]) {
    return <NoAccess msg={ErrorMessages.noDashboardAccess}></NoAccess>;
  }

  //NativeDashboard
  if (dashboardType === "native") {
    if (nativeReportsLoaded) {
      if (nativeReports.length > 0) {
        return (
          <NativeDashboardContainer
            nativeVisualsDossierGroups={nativeReports}
          ></NativeDashboardContainer>
        );
      } else {
        return <NoAccess msg={ErrorMessages.noDashboardAccess}></NoAccess>;
      }
    }
    return <Loader message={LoaderMessages.nativeMstrSession}></Loader>;
  }

  //KpiDashboard
  else if (dashboardType === "kpi") {
    const dashboards: KpiDashboardVisual[] = [];
    const userReports = UserSettingsService.reports();
    userReports.forEach((report) => {
      if (
        report.dashboardId &&
        launchDarklyFlags[
          getLaunchDarklyFlagKeyFromReportName(report.reportName)
        ]
      ) {
        const existingDashboard = dashboards.find(
          (dashboard) => dashboard.dashboardId === report.dashboardId
        );
        if (!existingDashboard) {
          dashboards.push({
            groupName: report.reportGroupName,
            dashboardId: report.dashboardId,
          });
        }
      }
    });
    if (dashboards.length === 0) {
      return <NoAccess msg={ErrorMessages.noDashboardAccess}></NoAccess>;
    }
    return (
      <KpiDashboardContainer dashboards={dashboards}></KpiDashboardContainer>
    );
  }

  //VisualMaximizedDashboard
  else {
    const userReports = UserSettingsService.reports();
    const dashboardGroupVisuals: DashboardGroupVisuals[] = [];
    userReports.forEach((report) => {
      if (
        report.visualKey &&
        report.pageKey &&
        launchDarklyFlags[
          getLaunchDarklyFlagKeyFromReportName(report.reportName)
        ]
      ) {
        const existingGroup = dashboardGroupVisuals.find(
          (dv) => dv.groupName === report.reportGroupName
        );
        if (existingGroup) {
          existingGroup.visuals.push({
            reportId: report.reportId,
            pageKey: report.pageKey,
            visualKey: report.visualKey,
            reportName: report.reportName,
            row: report.visualRows ?? 1,
            col: report.visualCols ?? 1,
          });
        } else {
          dashboardGroupVisuals.push({
            groupName: report.reportGroupName,
            visuals: [
              {
                reportId: report.reportId,
                visualKey: report.visualKey,
                pageKey: report.pageKey,
                reportName: report.reportName,
                row: report.visualRows ?? 1,
                col: report.visualCols ?? 1,
              },
            ],
          });
        }
      }
    });

    if (dashboardGroupVisuals.length === 0) {
      return <NoAccess msg={ErrorMessages.noDashboardAccess}></NoAccess>;
    }

    return (
      <VisualMaximizedDashboardContainer dossiers={dashboardGroupVisuals} />
    );
  }
}
export default DashboardPage;
