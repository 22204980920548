import DossierContainer from "../../mstr/dossier/DossierContainer";
import "./VisualMaximizedDashboardContainer.scss";
import { DashboardGroupVisuals } from "../../../model/userReportsModel";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { MSTR_QUERY_PARAM } from "../../../config/mstrConfig";

interface VisualMaximizedDashboardContainerProp {
  dossiers: DashboardGroupVisuals[];
}

function VisualMaximizedDashboardContainer(
  props: VisualMaximizedDashboardContainerProp
) {
  const navigate = useNavigate();
  const handleGraphicSelect = useCallback(
    (id: string) => {
      navigate(`/report?id=${id}&${MSTR_QUERY_PARAM}=true`);
    },
    [navigate]
  );

  let maxCol = 1;
  props.dossiers.forEach((d) => {
    d.visuals.forEach((v) => {
      if (v.col > maxCol) {
        maxCol = v.col;
      }
    });
  });

  const dossiers = props.dossiers.map((dossier) => (
    <div
      key={dossier.groupName}
      className="visualMaximizedDashboardDossierContainer"
    >
      <div className="visualMaximizedDashboardDossierModule">
        {dossier.groupName}
      </div>
      {dossier.visuals.map((visual, index) => (
        <div
          className="visualMaximizedDashboardDossier"
          key={visual.reportName}
        >
          <div className="visualMaximizedDashboardDossierHeader">
            {visual.reportName}
          </div>
          <div
            className="visualMaximizedDashboardDossierVisual"
            style={{
              height: `${
                150 * visual.row + (visual.col / maxCol >= 0.5 ? 34 : 0)
              }px`,
              maxWidth: `${(100 * visual.col) / maxCol}%`,
            }}
          >
            <DossierContainer
              dossierId={visual.reportId}
              containerId={`mstr-container-${dossier.groupName}-${index}`}
              visualId={visual.visualKey}
              size="maximized"
              resizeButtonVisible={false}
              onGraphicSelected={() => handleGraphicSelect(visual.reportId)}
              pageKey={visual.pageKey}
            />
          </div>
        </div>
      ))}
    </div>
  ));

  return <>{dossiers}</>;
}

export default VisualMaximizedDashboardContainer;
