import { useCallback, useEffect, useState } from "react";
import Loader from "../controls/loader/Loader";
import UserSettingsService, {
  UserSettingsInitializeResponse,
} from "../../services/userSettingsService";
import OneViewContainer from "../containers/oneViewContainer/OneViewContainer";
import VisualPage from "./VisualPage";
import MstrRestService, {
  MstrInitializeResponse,
} from "../../services/mstrRestService";
import { currentEnvironment } from "../../environments/environments";
import { useNavigate } from "react-router-dom";
import { ErrorMessages, LoaderMessages } from "../../utility/Messages";
import useCustomSearchParams from "../../utility/useCustomSearchParam";
import {
  LDContext,
  LDProvider,
  LDReactOptions,
} from "launchdarkly-react-client-sdk";
import { TokenContext } from "../../contexts";
import { useIdleTimer } from "react-idle-timer";
import { MSTR_IDLE_TIMEOUT, MSTR_QUERY_PARAM } from "../../config/mstrConfig";
import getError, { ErrorModel } from "../../utility/errorHelper";
import BridgeService, {
  InitializeBridgeResponse,
} from "../../services/bridgeService";

function LandingPage() {
  const [mstrInitialized, setMstrInitialized] = useState(false);
  const [mstrInitializedResponse, setMstrInitializedResponse] =
    useState<MstrInitializeResponse>();

  const [bridgeInitialized, setBridgeInitialized] = useState(false);
  const [bridgeInitializeResponse, setBridgeInitializeResponse] = useState<
    InitializeBridgeResponse | undefined
  >();

  const [userSettingsInitialized, setUserSettingsInitialized] = useState(false);
  const [userSettingsInitializeResponse, setUserSettingsInitializeResponse] =
    useState<UserSettingsInitializeResponse | undefined>();

  const mstrInvoked = (useCustomSearchParams(`${MSTR_QUERY_PARAM}`) ??
    false) as boolean;

  const navigate = useNavigate();

  const navigateToError = useCallback(
    (error: ErrorModel) => {
      navigate("/error", {
        state: {
          error: error,
        },
      });
    },
    [navigate]
  );

  const onIdleTimeout = useCallback(() => {
    navigateToError(getError(ErrorMessages.sessionError));
  }, [navigateToError]);

  const getUserSettings = useCallback(async (attempts: number) => {
    let userSettingsResponses;
    while (attempts-- > 0) {
      userSettingsResponses = await UserSettingsService.initialize();
      if (
        userSettingsResponses.reportsInitialized &&
        userSettingsResponses.accountInitialized
      )
        return userSettingsResponses;
    }
    return userSettingsResponses;
  }, []);

  const getBridge = useCallback((attempts: number) => {
    let initializeBridgeResponse;
    while (attempts-- > 0) {
      initializeBridgeResponse = BridgeService.initialize();
      if (initializeBridgeResponse.bridgeUserInitialized)
        return initializeBridgeResponse;
    }
    return initializeBridgeResponse;
  }, []);

  const getMstr = useCallback(
    async (attempts: number, mstrInvoked: boolean) => {
      let response;
      while (attempts-- > 0) {
        response = await MstrRestService.initialize(mstrInvoked);
        if (
          response.token &&
          response.userInitialized &&
          response.companyAttributesInitialized &&
          response.enterpriseAttributesInitialized
        ) {
          return response;
        }
      }
      return response;
    },
    []
  );

  useEffect(() => {
    initMstr();

    async function initMstr() {
      const mstrResponse = await getMstr(3, mstrInvoked);
      if (mstrResponse) setMstrInitializedResponse(mstrResponse);
      if (mstrInvoked) setMstrInitialized(true);
    }
  }, [getMstr, mstrInvoked]);

  useEffect(() => {
    initBridge();

    function initBridge() {
      if (!mstrInitialized || !mstrInitializedResponse?.token) return;

      const bridgeResponse = getBridge(100);
      if (bridgeResponse) setBridgeInitializeResponse(bridgeResponse);
      setBridgeInitialized(true);
    }
  }, [getBridge, mstrInitialized, mstrInitializedResponse?.token]);

  useEffect(() => {
    initUserSettings();

    async function initUserSettings() {
      if (
        !bridgeInitialized ||
        !bridgeInitializeResponse?.bridgeUserInitialized
      ) {
        return;
      }

      const userSettingsResponse = await getUserSettings(3);
      if (userSettingsResponse)
        setUserSettingsInitializeResponse(userSettingsResponse);
      setUserSettingsInitialized(true);
    }
  }, [
    bridgeInitializeResponse?.bridgeUserInitialized,
    bridgeInitialized,
    getUserSettings,
  ]);

  useEffect(() => {
    if (mstrInitialized && !mstrInitializedResponse?.token)
      navigateToError(getError(ErrorMessages.mstrDown));
    else if (
      bridgeInitialized &&
      !bridgeInitializeResponse?.bridgeUserInitialized
    )
      navigateToError(getError(ErrorMessages.settingApiDown));
    else if (
      userSettingsInitialized &&
      !userSettingsInitializeResponse?.reportsInitialized
    )
      navigateToError(getError(ErrorMessages.settingApiDown));
    else if (
      userSettingsInitialized &&
      !userSettingsInitializeResponse?.accountInitialized
    )
      navigateToError(getError(ErrorMessages.accountApiDown));
  }, [
    userSettingsInitialized,
    userSettingsInitializeResponse,
    navigateToError,
    bridgeInitialized,
    bridgeInitializeResponse?.bridgeUserInitialized,
    mstrInitialized,
    mstrInitializedResponse?.token,
  ]);

  useIdleTimer({
    onIdle: onIdleTimeout,
    timeout: MSTR_IDLE_TIMEOUT - 5 * 60 * 1000,
    crossTab: true,
  });

  if (mstrInitialized && bridgeInitialized && userSettingsInitialized) {
    const clientId =
      currentEnvironment().customConfiguration.launchDarklyClientId;
    const context: LDContext = {
      kind: "user",
      key: BridgeService.sub(),
      "common-org-id": UserSettingsService.commonOrgIds(),
    };
    const options: LDReactOptions = { useCamelCaseFlagKeys: false };

    return (
      <LDProvider
        clientSideID={clientId}
        context={context}
        reactOptions={options}
      >
        <OneViewContainer>
          <TokenContext.Provider value={mstrInitializedResponse?.token}>
            <VisualPage />
          </TokenContext.Provider>
        </OneViewContainer>
      </LDProvider>
    );
  }

  return <Loader message={LoaderMessages.mstrSession} />;
}

export default LandingPage;
