import { LogModel } from "../model/logModel";
import { currentEnvironment } from "../environments/environments";
import { UserSettingsApiService } from "./apiService";
import { getBridgeUser } from "../utility/sessionStorageHelper";

export const LogService = {
  log: (model: LogModel) => {
    try {
      const bridgeUserModel = getBridgeUser();
      if (!bridgeUserModel) return;
      console.log(model.message);
      const environment = currentEnvironment();
      const path = `${environment.customConfiguration.userSettingUrl}/user/log`;
      const message = `[${model.file} - ${model.method}][${bridgeUserModel.userName}}]${model.message}`;
      UserSettingsApiService.post(
        path,
        { message: message, type: model.type },
        bridgeUserModel.accessToken
      ).catch((err) => console.log(err));
    } catch (err) {
      console.log(err);
    }
  },
};
