import "./Footer.scss";
function Footer() {
  return (
    <span className="footer">
      ©{new Date().getFullYear()} Cox Automotive. All Rights Reserved.
    </span>
  );
}

export default Footer;
