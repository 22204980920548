import { useCallback, useEffect, useRef, useState } from "react";
import FilterBar from "../filterBar/FilterBar";
import Select from "../select/Select";
import SlideOut from "../slideOut/SlideOut";
import {
  COMPANY_ATTRIBUTE_ID,
  ENTERPRISE_ATTRIBUTE_ID,
} from "../../../config/mstrConfig";
import "./NativeFilter.scss";
import NativeDossierService from "../../../services/nativeDossierService";
import { FilterCurrentSelection } from "../../../model/dossierDefinitionModel";
import {
  FilterModel,
  LoadedNativeDossier,
  SelectOption,
  TempSelectionFilterModel,
} from "../../../model/userNativeReportsModel";
import { FilterPreference } from "../../../model/userPreferenceModel";
import UserSettingsService from "../../../services/userSettingsService";

type NativeFilterType = "enterprise" | "company" | "default";

interface NativeFilterProps {
  loadedDossiers: LoadedNativeDossier[];
}

function NativeFilter(props: NativeFilterProps) {
  const [showFilter, setShowFilter] = useState(false);
  const [enterprises, setEnterprises] = useState<SelectOption[]>([]);
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const [filteredCompanies, setFilteredCompanies] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<FilterModel>({
    enterprises: [],
    companies: [],
  });
  const [applyingFilter, setApplyingFilter] = useState(false);
  const [displayEnterpriseName, setDisplayEnterpriseName] =
    useState<string>(" ");
  const [displayCompanyName, setDisplayCompanyName] = useState<string>(" ");

  const tempSelectionFilterModel = useRef<TempSelectionFilterModel>({
    enterprises: [],
    companies: [],
    enterpriseUpdated: false,
    companyUpdated: false,
  });

  const setTempSelection = useCallback(
    (nativeFilterType: NativeFilterType, selectOptions: SelectOption[]) => {
      if (nativeFilterType === "enterprise") {
        tempSelectionFilterModel.current.enterprises = selectOptions;
        tempSelectionFilterModel.current.enterpriseUpdated = true;
      } else if (nativeFilterType === "company") {
        tempSelectionFilterModel.current.companies = selectOptions;
        tempSelectionFilterModel.current.companyUpdated = true;
      } else {
        tempSelectionFilterModel.current.enterpriseUpdated = false;
        tempSelectionFilterModel.current.enterprises = [];
        tempSelectionFilterModel.current.companyUpdated = false;
        tempSelectionFilterModel.current.companies = [];
      }
    },
    []
  );

  const filterOptions = useCallback(
    (companies: SelectOption[], selectOptions: SelectOption[]) => {
      const hierarchies = UserSettingsService.account()?.hierarchies;
      if (hierarchies && hierarchies.length > 0) {
        const relatedCompanies = selectOptions.length === 0
          ? hierarchies.map((h) => h.company)
          : hierarchies
            .filter((h) => selectOptions.some((s) => s.label === h.enterprise))
            .map((h) => h.company);
        const filteredCompanyOptions = companies.filter((c) =>
          relatedCompanies.includes(c.label)
        );
        setTempSelection("company", filteredCompanyOptions)
        setFilteredCompanies(filteredCompanyOptions);
      }
    },
    [setTempSelection]
  );

  const applyFilter = useCallback(
    async (toBeAppliedFilters: TempSelectionFilterModel) => {
      if (
        !toBeAppliedFilters.companyUpdated &&
        !toBeAppliedFilters.enterpriseUpdated
      )
        return;
      setApplyingFilter(true);
      const dossiersGlobalFilterKeys =
        NativeDossierService.getNativeDossierGlobalFilterKeys();
      const {
        currentEnterpriseSelection,
        currentCompanySelection,
      }: {
        currentEnterpriseSelection: FilterCurrentSelection | undefined;
        currentCompanySelection: FilterCurrentSelection | undefined;
      } = NativeDossierService.getCurrentSelection(toBeAppliedFilters);
      const promises: Promise<void>[] = [];
      props.loadedDossiers.forEach((loadedDossier) => {
        const dossierGlobalFilterKeys = dossiersGlobalFilterKeys.find(
          (d) => d.dossierId === loadedDossier.id
        );
        if (dossierGlobalFilterKeys)
          promises.push(
            NativeDossierService.applyGlobalFilters(
              loadedDossier,
              dossierGlobalFilterKeys,
              currentEnterpriseSelection,
              currentCompanySelection
            )
          );
      });
      await Promise.all(promises);
      const existingFilters =
        NativeDossierService.getLastAppliedGlobalFilters();
      const filterPreference: FilterPreference = {
        enterprises: toBeAppliedFilters.enterpriseUpdated
          ? toBeAppliedFilters.enterprises.map((e) => e.value)
          : existingFilters.enterprises,
        companies: toBeAppliedFilters.companyUpdated
          ? toBeAppliedFilters.companies.map((c) => c.value)
          : existingFilters.companies,
      };
      const filterModel =
        NativeDossierService.getFilterModelFromFilterPreference(
          filterPreference
        );
      setSelected(filterModel);
      await NativeDossierService.setLastAppliedGlobalFilters(
        filterPreference
      );

      setApplyingFilter(false);
      setTempSelection("default", []);
    },
    [props.loadedDossiers, setTempSelection]
  );

  const handleFilterToggle = useCallback(
    async (apply: boolean) => {
      setShowFilter((prev) => !prev);
      if (apply) {
        await applyFilter({ ...tempSelectionFilterModel.current });
      } else {
        setTempSelection("default", []);
      }
    },
    [applyFilter, setTempSelection]
  );

  const handleDisplayName = useCallback(
    (all: SelectOption[], selected: SelectOption[]) => {
      let value = " ";
      if (selected.length === 0) {
        value = "ALL";
      } else if (all.length === 0) {
        value = " ";
      } else if (all.length === 1) {
        value = all[0].label;
      } else {
        value = selected.map((e) => e.label).join(" , ");
      }
      return value;
    },
    []
  );

  const init = useCallback(() => {
    const enterprises = NativeDossierService.getAttributes(
      ENTERPRISE_ATTRIBUTE_ID
    );
    if (enterprises) {
      setEnterprises(enterprises);
    }
    const companies =
      NativeDossierService.getAttributes(COMPANY_ATTRIBUTE_ID);
    if (companies) {
      setCompanies(companies);
      setFilteredCompanies(companies);
    }
    const filterPreference =
      NativeDossierService.getLastAppliedGlobalFilters();
    const filters =
      NativeDossierService.getFilterModelFromFilterPreference(
        filterPreference
      );
    setSelected(filters);
    filterOptions(companies ?? [], filters.enterprises)
  }, [filterOptions])

  useEffect(() => {
    setDisplayEnterpriseName(
      handleDisplayName(enterprises, selected.enterprises)
    );
  }, [enterprises, handleDisplayName, selected.enterprises]);

  useEffect(() => {
    setDisplayCompanyName(handleDisplayName(companies, selected.companies));
  }, [companies, handleDisplayName, selected.companies]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <FilterBar
        enterprise={displayEnterpriseName}
        company={displayCompanyName}
        click={() => handleFilterToggle(false)}
      ></FilterBar>
      <SlideOut
        show={showFilter}
        onClose={handleFilterToggle}
        disabled={applyingFilter}
      >
        <div className="componentsContainer" data-testid="componentsContainer">
          <div className="components" data-testid="enterprise">
            <Select
              label="Enterprise"
              options={enterprises}
              multiple
              values={selected.enterprises}
              readonly={applyingFilter}
              onChange={(selections) => {
                setTempSelection("enterprise", selections);
                filterOptions(companies, selections);
              }}
            ></Select>
          </div>
          <div className="components" data-testid="company">
            <Select
              label="Company"
              options={filteredCompanies}
              multiple
              values={tempSelectionFilterModel.current.companyUpdated ? tempSelectionFilterModel.current.companies : selected.companies}
              readonly={applyingFilter}
              onChange={(selections) => {
                setTempSelection("company", selections);
              }}
            ></Select>
          </div>
        </div>
      </SlideOut>
    </>
  );
}

export default NativeFilter;
