import { BridgeUser } from "../model/bridgeUserModel";
import {
  clearBridgeUser,
  getBridgeUser,
  setBridgeUser,
} from "../utility/sessionStorageHelper";

export interface InitializeBridgeResponse {
  bridgeUserInitialized?: boolean;
}

function setUser(user: BridgeUser) {
  setBridgeUser(user);
}
function getUser() {
  return getBridgeUser();
}

function sub() {
  return getBridgeUser()?.sub ?? "";
}

function initialize(): InitializeBridgeResponse {
  let response = false;
  const bridgeUser = getBridgeUser();
  if (bridgeUser) response = true;
  return { bridgeUserInitialized: response };
}

function clear() {
  clearBridgeUser();
}
const BridgeService = { initialize, setUser, getUser, sub, clear };
export default BridgeService;
