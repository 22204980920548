import { useCallback, useContext, useEffect, useState } from "react";
import NativeDossierService from "../../../services/nativeDossierService";
import { LogType } from "../../../enum";
import { LogService } from "../../../services/logService";
import {
  LoadedNativeDossier,
  NativeVisualsDossier,
} from "../../../model/userNativeReportsModel";
import { FilterCurrentSelection } from "../../../model/dossierDefinitionModel";
import { TokenContext } from "../../../contexts";

interface NativeDossierProps {
  nativeVisualsDossiers: NativeVisualsDossier[];
  onDossierRefreshed: (loadedDossier: LoadedNativeDossier) => void;
  enterprises?: FilterCurrentSelection;
  companies?: FilterCurrentSelection;
}

function NativeDossier(props: NativeDossierProps) {
  const { nativeVisualsDossiers, onDossierRefreshed, enterprises, companies } =
    props;
  const [mstrEnvironment, setMstrEnvironment] = useState<any>();
  const token = useContext(TokenContext);

  const loadVisualizationsFromDossier = useCallback(
    async (
      mstrEnvironment: any,
      commonMstrVisualsDossier: NativeVisualsDossier,
      dossiers: any[],
      signal: AbortSignal,
      attemptLeft: number
    ) => {
      let mstrDossier: any;
      try {
        //Load Dossier
        mstrDossier = await NativeDossierService.loadDossier(
          mstrEnvironment,
          commonMstrVisualsDossier.dossierId
        );
        if (!mstrDossier) {
          if (attemptLeft === 0) {
            throw Error(
              `unable to load dossier with dossierId: ${commonMstrVisualsDossier.dossierId}`
            );
          } else {
            setTimeout(
              () =>
                loadVisualizationsFromDossier(
                  mstrEnvironment,
                  commonMstrVisualsDossier,
                  dossiers,
                  signal,
                  --attemptLeft
                ),
              5000
            );
          }
          return;
        }
        dossiers.push(mstrDossier);

        //Refresh Dossier to load visualization
        const isRefreshed = await NativeDossierService.refreshDossier(
          mstrDossier,
          commonMstrVisualsDossier.visuals.map((visual: string) => ({
            key: visual,
            container: document.getElementById(
              `${commonMstrVisualsDossier.dossierId}-${visual}`
            ),
          })),
          signal
        );
        if (!isRefreshed) {
          throw Error(
            `unable to refresh dossier with dossierId: ${commonMstrVisualsDossier.dossierId}`
          );
        }

        const loadedDossier = {
          id: commonMstrVisualsDossier.dossierId,
          dossier: mstrDossier,
        };

        //Apply initial filters
        const dossiersGlobalFilterKeys =
          NativeDossierService.getNativeDossierGlobalFilterKeys();
        const dossierGlobalFilterKeys = dossiersGlobalFilterKeys.find(
          (d) => d.dossierId === commonMstrVisualsDossier.dossierId
        );
        if (dossierGlobalFilterKeys) {
          await NativeDossierService.applyGlobalFilters(
            loadedDossier,
            dossierGlobalFilterKeys,
            enterprises,
            companies
          );
        }

        onDossierRefreshed(loadedDossier);
      } catch (e) {
        LogService.log({
          message: `${e}`,
          type: LogType.Error,
          method: "loadVisualizationsFromDossier",
          file: "NativeDossier.tsx",
        });
        if (mstrDossier)
          NativeDossierService.unloadDossier(mstrEnvironment, mstrDossier);
      }
    },
    [companies, enterprises, onDossierRefreshed]
  );

  const loadVisualizationsFromDossiers = useCallback(
    (
      mstrEnvironment: any,
      commonMstrVisualsDossiers: NativeVisualsDossier[],
      dossiers: any[],
      signal: AbortSignal
    ) => {
      commonMstrVisualsDossiers.map(
        (commonMstrVisualsDossier: NativeVisualsDossier) =>
          loadVisualizationsFromDossier(
            mstrEnvironment,
            commonMstrVisualsDossier,
            dossiers,
            signal,
            5
          )
      );
    },
    [loadVisualizationsFromDossier]
  );

  useEffect(() => {
    setupMstrEnvironment();
    async function setupMstrEnvironment() {
      const mstrEnvironment = await NativeDossierService.createMstrEnvironment(
        token!
      );
      setMstrEnvironment(mstrEnvironment);
    }
  }, [token]);

  useEffect(() => {
    if (!mstrEnvironment) return;
    const abortController = new AbortController();
    const dossiers: any[] = [];
    loadVisualizationsFromDossiers(
      mstrEnvironment,
      nativeVisualsDossiers,
      dossiers,
      abortController.signal
    );
    return function cleanup() {
      try {
        abortController.abort("clean up on unmount");
        NativeDossierService.unloadDossiers(mstrEnvironment, dossiers);
      } catch (e) {
        LogService.log({
          message: `${e}`,
          type: LogType.Error,
          method: "cleanup",
          file: "NativeDossier.tsx",
        });
      }
    };
  }, [nativeVisualsDossiers, loadVisualizationsFromDossiers, mstrEnvironment]);

  return <></>;
}

export default NativeDossier;
