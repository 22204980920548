import {
  COMPANY_ATTRIBUTE_ID,
  ENTERPRISE_ATTRIBUTE_ID,
  MSTR_PROJECT_ID,
} from "../config/mstrConfig";
import { LogType } from "../enum";
import { currentEnvironment } from "../environments/environments";
import {
  DossierGlobalFilterKey,
  FilterCurrentSelection,
} from "../model/dossierDefinitionModel";
import {
  FilterModel,
  LoadedNativeDossier,
  TempSelectionFilterModel,
} from "../model/userNativeReportsModel";
import { FilterPreference, UserPreference } from "../model/userPreferenceModel";
import {
  getNativeDossierGlobalFilterKeys as getNativeDossierGlobalFilterKeysFromSession,
  getUserPreference,
} from "../utility/sessionStorageHelper";
import { LogService } from "./logService";
import MstrRestService from "./mstrRestService";
import UserSettingsService from "./userSettingsService";

async function createMstrEnvironment(token: string) {
  const environment = currentEnvironment();
  try {
    const mstr = (window as any).microstrategy;
    mstr.nativeEmbedding.featureFlags.multipleDossiers = true;
    return await mstr.embeddingComponent.environments.create({
      serverUrl: environment.customConfiguration.mstrUrl,
      getAuthToken: async () => Promise.resolve(token),
    });
  } catch (e) {
    LogService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "createMstrEnvironment",
      file: "NativeDossierService.ts",
    });
  }
}

function unloadDossiers(mstrEnvironment: any, dossiers: any[]) {
  dossiers.forEach((dossier) => {
    try {
      unloadDossier(mstrEnvironment, dossier);
    } catch (e) {
      LogService.log({
        message: `${e}`,
        type: LogType.Error,
        method: "unloadDossiers",
        file: "NativeDossierService.ts",
      });
    }
  });
}

function unloadDossier(mstrEnvironment: any, dossier: any) {
  mstrEnvironment.unloadDossier(dossier).catch((e: any) =>
    LogService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "unloadDossier",
      file: "NativeDossierService.ts",
    })
  );
}

async function loadDossier(mstrEnvironment: any, dossierId: string) {
  try {
    const dossier = await mstrEnvironment.loadDossier({
      projectId: MSTR_PROJECT_ID,
      objectId: dossierId,
    });
    return dossier;
  } catch (e) {
    LogService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "loadDossier",
      file: "NativeDossierService.ts",
    });
  }
}

async function refreshDossier(
  mstrDossier: any,
  vizAndContainers: { key: string; container: HTMLElement | null }[],
  signal: AbortSignal
): Promise<boolean> {
  try {
    await mstrDossier.refresh(vizAndContainers, {
      signal: signal,
    });
    return true;
  } catch (e) {
    LogService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "refreshDossier",
      file: "NativeDossierService.ts",
    });
    return false;
  }
}

async function applyFilter(
  mstrDossier: any,
  key: string,
  currentSelection: FilterCurrentSelection
) {
  try {
    await mstrDossier.applyFilter({
      key: key,
      currentSelection: currentSelection,
    });
  } catch (e) {
    LogService.log({
      message: `${e}`,
      type: LogType.Error,
      method: "applyFilter",
      file: "NativeDossierService.ts",
    });
  }
}

async function applyGlobalFilters(
  loadedDossier: LoadedNativeDossier,
  dossierGlobalFilterKeys: DossierGlobalFilterKey,
  enterprises?: FilterCurrentSelection,
  companies?: FilterCurrentSelection
) {
  if (enterprises) {
    for (let i = 0; i < dossierGlobalFilterKeys.enterpriseKeys.length; i++) {
      await applyFilter(
        loadedDossier.dossier,
        dossierGlobalFilterKeys.enterpriseKeys[i],
        enterprises
      );
    }
  }

  if (companies) {
    for (let i = 0; i < dossierGlobalFilterKeys.companyKeys.length; i++) {
      await applyFilter(
        loadedDossier.dossier,
        dossierGlobalFilterKeys.companyKeys[i],
        companies
      );
    }
  }
}

function getLastAppliedGlobalFilters(): FilterPreference {
  const userPreference = getUserPreference();
  return {
    enterprises: userPreference?.enterprises ?? [],
    companies: userPreference?.companies ?? [],
  };
}

async function setLastAppliedGlobalFilters(filterPreference: FilterPreference) {
  const userPreference: UserPreference = {
    ...filterPreference,
    sub: "",
  };
  await UserSettingsService.setUserPreference(userPreference);
}

function getNativeDossierGlobalFilterKeys() {
  return getNativeDossierGlobalFilterKeysFromSession();
}

function getCurrentSelection(toBeAppliedFilters: TempSelectionFilterModel) {
  const allEnterpriseSelected = toBeAppliedFilters.enterprises.length === 0;
  const currentEnterpriseSelection: FilterCurrentSelection | undefined =
    toBeAppliedFilters.enterpriseUpdated
      ? {
          allSelected: allEnterpriseSelected,
          elements: allEnterpriseSelected
            ? undefined
            : toBeAppliedFilters.enterprises.map((e) => ({
                id: e.value,
              })),
        }
      : undefined;
  const allCompanySelected = toBeAppliedFilters.companies.length === 0;
  const currentCompanySelection: FilterCurrentSelection | undefined =
    toBeAppliedFilters.companyUpdated
      ? {
          allSelected: allCompanySelected,
          elements: allCompanySelected
            ? undefined
            : toBeAppliedFilters.companies.map((e) => ({
                id: e.value,
              })),
        }
      : undefined;
  return { currentEnterpriseSelection, currentCompanySelection };
}

const getFilterModelFromFilterPreference = (
  filterPreference: FilterPreference
): FilterModel => {
  const filterModel: FilterModel = { enterprises: [], companies: [] };
  const enterprises = getAttributes(ENTERPRISE_ATTRIBUTE_ID);
  const companies = getAttributes(COMPANY_ATTRIBUTE_ID);
  filterPreference.enterprises.forEach((e) => {
    const label = enterprises?.find((en) => en.value === e)?.label;
    if (label) filterModel.enterprises.push({ value: e, label: label });
  });
  filterPreference.companies.forEach((e) => {
    const label = companies?.find((en) => en.value === e)?.label;
    if (label) filterModel.companies.push({ value: e, label: label });
  });
  return filterModel;
};

function getAttributes(attributeId: string) {
  const elements = MstrRestService.attributeElements(attributeId);
  if (elements) {
    return attributeId === ENTERPRISE_ATTRIBUTE_ID
      ? elements.map((e) => ({
          label: e.name!,
          value: `${e.id.substring(
            0,
            e.id.indexOf(";") + 1
          )}${ENTERPRISE_ATTRIBUTE_ID}`,
        }))
      : elements.map((e) => ({
          label: e.name!.substring(0, e.name!.indexOf(":")),
          value: `${e.id.substring(
            0,
            e.id.indexOf(";") + 1
          )}${COMPANY_ATTRIBUTE_ID}`,
        }));
  }
}

const NativeDossierService = {
  createMstrEnvironment,
  unloadDossiers,
  unloadDossier,
  loadDossier,
  refreshDossier,
  applyFilter,
  applyGlobalFilters,
  getLastAppliedGlobalFilters,
  setLastAppliedGlobalFilters,
  getNativeDossierGlobalFilterKeys,
  getCurrentSelection,
  getFilterModelFromFilterPreference,
  getAttributes,
};

export default NativeDossierService;
