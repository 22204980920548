import { LDFlagSet } from "launchdarkly-react-client-sdk";
import {
  NativeVisualsDossierGroup,
  NativeVisualsPage,
} from "../model/userNativeReportsModel";
import {
  getNativeDossierGlobalFilterKeys,
  getUserNativeReports,
  setNativeDossierGlobalFilterKeys,
  setUserNativeReports,
} from "../utility/sessionStorageHelper";
import MstrRestService from "./mstrRestService";
import UserSettingsService from "./userSettingsService";
import { getLaunchDarklyFlagKeyFromReportName } from "../utility/launchDarklyFlagHelper";

async function reports(flags: LDFlagSet): Promise<NativeVisualsDossierGroup[]> {
  const reports = UserSettingsService.reports();
  let nativeReports = getUserNativeReports();
  let nativeDossierGlobalFilterKeys = getNativeDossierGlobalFilterKeys();
  if (
    nativeReports.length === 0 ||
    nativeDossierGlobalFilterKeys.length === 0
  ) {
    for (let i = 0; i < reports.length; i++) {
      const report = reports[i];
      const dossierDefinition = await MstrRestService.dossierDefinitions(
        report.reportId
      );
      const dossierKpiVisualization: NativeVisualsPage[] = [];
      const dossierEnterpriseKeys: string[] = [];
      const dossierCompanyKeys: string[] = [];
      dossierDefinition?.chapters.forEach((chapter) => {
        chapter.pages.forEach((page) => {
          const pageKpiVisualization = page.visualizations
            .filter((v) => v.name.toLowerCase().includes("kpi"))
            .map((v) => v.key);
          if (pageKpiVisualization.length > 0) {
            dossierKpiVisualization.push({
              pageKey: page.key,
              visuals: pageKpiVisualization,
            });
          }
        });
        const enterpriseKey = chapter.filters.find((s) =>
          s.name.toLowerCase().includes("enterprise")
        )?.key;
        if (enterpriseKey) dossierEnterpriseKeys.push(enterpriseKey);
        const companyKey = chapter.filters.find((s) =>
          s.name.toLowerCase().includes("company")
        )?.key;
        if (companyKey) dossierCompanyKeys.push(companyKey);
      });
      if (dossierKpiVisualization.length > 0) {
        nativeReports.push({
          dossierId: report.reportId,
          nativeVisualsPages: dossierKpiVisualization,
        });
      }
      nativeDossierGlobalFilterKeys.push({
        dossierId: report.reportId,
        enterpriseKeys: dossierEnterpriseKeys,
        companyKeys: dossierCompanyKeys,
      });
    }
    setUserNativeReports(nativeReports);
    setNativeDossierGlobalFilterKeys(nativeDossierGlobalFilterKeys);
  }
  const nativeVisualsDossierGroup: NativeVisualsDossierGroup[] = [];
  nativeReports.forEach((nv) => {
    const report = reports.find((r) => r.reportId === nv.dossierId);
    if (report) {
      const group = report.reportGroupName;
      const hasAccess =
        flags[getLaunchDarklyFlagKeyFromReportName(report.reportName)];
      if (group && hasAccess) {
        const nativeGroup = nativeVisualsDossierGroup.find(
          (ng) => ng.group === group
        );
        if (nativeGroup) nativeGroup.nativePagesDossiers.push(nv);
        else
          nativeVisualsDossierGroup.push({
            group: group,
            nativePagesDossiers: [nv],
          });
      }
    }
  });
  return nativeVisualsDossierGroup;
}

const NativeDashboardService = { reports };
export default NativeDashboardService;
