import { Deployment } from "./deployment";
import { AuthConfiguration, bridge, BridgeRuntime } from "@bridge/okta-auth";
import { BridgeBarRuntime } from "./bridgeBarRuntime";
import { currentRuntime, Runtime } from "./runtime";
import { CustomConfiguration } from "./customConfiguration";

export interface Environment {
  runtime: Runtime;
  authConfig: AuthConfiguration;
  applicationId: string;
  bridgeBarRuntime: BridgeBarRuntime;
  customConfiguration: CustomConfiguration;
}

const environments: Environment[] = [
  //local
  {
    runtime: {
      deployment: Deployment.LOCAL,
    },
    authConfig: {
      idp: bridge(BridgeRuntime.NON_PRODUCTION),
      clientId: "0oaxkfwx7npdGtxd6357",
      scopes: ["bridge.signin", "dealer-ops.shared.reporting.user.read", "dealer-ops.shared.reporting.user.write"],
      renewTokensOnExpiration: true,
      redirectUri: `${window.location.origin}/loginCallback`,
      postLogoutRedirectUri: `${window.location.origin}`,
    },
    applicationId: "5eb5ffb9-48c2-4f69-859d-32de9a0553c9",
    bridgeBarRuntime: BridgeBarRuntime.DEVELOPMENT,
    customConfiguration: {
      userSettingUrl:
        "https://settings-api-dev.reports-test.coxautoinc.com/api",
      mstrUrl: "https://ui.reports-test.coxautoinc.com/MicroStrategyLibrary",
      launchDarklyClientId: "5e1cbf61201a6c08e17efa00",
      product: "DMS+ Reporting (DEV)",
    },
  },
  //dev
  {
    runtime: {
      deployment: Deployment.DEV,
    },
    authConfig: {
      idp: bridge(BridgeRuntime.NON_PRODUCTION),
      clientId: "0oaxkfwx7npdGtxd6357",
      scopes: ["bridge.signin", "dealer-ops.shared.reporting.user.read", "dealer-ops.shared.reporting.user.write"],
      renewTokensOnExpiration: true,
      redirectUri: `${window.location.origin}/loginCallback`,
      postLogoutRedirectUri: `${window.location.origin}`,
    },
    applicationId: "5eb5ffb9-48c2-4f69-859d-32de9a0553c9",
    bridgeBarRuntime: BridgeBarRuntime.DEVELOPMENT,
    customConfiguration: {
      userSettingUrl:
        "https://settings-api-dev.reports-test.coxautoinc.com/api",
      mstrUrl: "https://ui.reports-test.coxautoinc.com/MicroStrategyLibrary",
      launchDarklyClientId: "5e1cbf61201a6c08e17efa00",
      product: "DMS+ Reporting (DEV)",
    },
  },
  //qa
  {
    runtime: {
      deployment: Deployment.QA,
    },
    authConfig: {
      idp: bridge(BridgeRuntime.NON_PRODUCTION),
      clientId: "0oa15dee0r0NVnY00358",
      scopes: ["bridge.signin", "dealer-ops.shared.reporting.user.read", "dealer-ops.shared.reporting.user.write"],
      renewTokensOnExpiration: true,
      redirectUri: `${window.location.origin}/loginCallback`,
      postLogoutRedirectUri: `${window.location.origin}`,
    },
    applicationId: "1c042220-675d-47dc-ae25-4d10c9065a5c",
    bridgeBarRuntime: BridgeBarRuntime.QA,
    customConfiguration: {
      userSettingUrl: "https://settings-api-qa.reports-test.coxautoinc.com/api",
      mstrUrl: "https://ui-qa.reports-test.coxautoinc.com/MicroStrategyLibrary",
      launchDarklyClientId: "5df92a1779abf3088e0425a7",
      product: "DMS+ Reporting (QA)",
    },
  },
  //prod
  {
    runtime: {
      deployment: Deployment.PROD,
    },
    authConfig: {
      idp: bridge(BridgeRuntime.PRODUCTION),
      clientId: "0oazym3ci89SXUtiB357",
      scopes: ["bridge.signin", "dealer-ops.shared.reporting.user.read", "dealer-ops.shared.reporting.user.write"],
      renewTokensOnExpiration: true,
      redirectUri: `${window.location.origin}/loginCallback`,
      postLogoutRedirectUri: `${window.location.origin}`,
    },
    applicationId: "49c9ed87-5248-4cb5-9439-e697b9aefdd0",
    bridgeBarRuntime: BridgeBarRuntime.PRODUCTION,
    customConfiguration: {
      userSettingUrl: "https://settings-api.reports.coxautoinc.com/api",
      mstrUrl: "https://ui.reports.coxautoinc.com/MicroStrategyLibrary",
      launchDarklyClientId: "5df92a1779abf3088e0425a8",
      product: "DMS+ Reporting",
    },
  },
];

export function currentEnvironment(): Environment | never {
  const runtime = currentRuntime();
  const environment = environments.find((environment) => {
    return environment.runtime.deployment === runtime.deployment;
  });
  if (!environment) {
    throw new Error(`Runtime ${JSON.stringify(runtime)} is not supported`);
  }
  return environment;
}
