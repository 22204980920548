import { LogType } from "../enum";
import { currentEnvironment } from "../environments/environments";
import { MstrMessage } from "../model/mstrMessageModel";
import { LogService } from "./logService";

const MstrMessagingService = {
  postToDossier: (id: string, data: MstrMessage, timeout?: number) => {
    let count = 0;
    timeout = timeout ?? 100;
    const interval = setInterval(() => {
      try {
        if (++count > 100) {
          clearInterval(interval);
          LogService.log({
            message:
              "Unable to send message to dossier as iframe is not loaded even after all retries to check iframe load exhausted.",
            type: LogType.Error,
            method: "postMessageToDossier",
            file: "mstrMessagingService.ts",
          });
          return;
        }
        const frame = document.getElementById(id)?.querySelector("iframe");
        if (frame && frame.contentWindow) {
          clearInterval(interval);
          frame.contentWindow.postMessage(
            data,
            currentEnvironment().customConfiguration.mstrUrl
          );
        }
      } catch (err) {
        LogService.log({
          message: `${err}`,
          type: LogType.Error,
          method: "postMessageToDossier",
          file: "mstrMessagingService.ts",
        });
      }
    }, timeout);
  },
};

export default MstrMessagingService;
