import { Environment } from "../environments/environments";

const BridgeBarService = {
  initialize: (sessionId: string | undefined, environment: Environment) => {
    try {
      var bbEl = window.document.getElementById("bridge-bar") as any;

      if (!bbEl) {
        return "Unable to find the bridge bar element with id 'bridge-bar'";
      }

      bbEl.addEventListener("initialized", function () {
        bbEl.bb
          .render({
            env: environment.bridgeBarRuntime,
            sessionId: sessionId ?? "no_session_present",
            solutionGroupCode: environment.applicationId,
            theme: "Bianco",
          })
          .onStateDetermined(function (state: any) {
            if (state.visible) {
              window.document.querySelector("body")!.className +=
                " has-bridge-bar";
            }
          });
      });
    } catch (err) {
      return `${err}`;
    }
  },
};

export default BridgeBarService;
