import { FilterCurrentSelection } from "../../../model/dossierDefinitionModel";
import {
  LoadedNativeDossier,
  NativeVisualsDossier,
} from "../../../model/userNativeReportsModel";
import NativeDossier from "./NativeDossier";

export interface NativeDossierContainerProp {
  nativeVisualsDossiers: NativeVisualsDossier[];
  onDossierRefreshed: (loadedDossier: LoadedNativeDossier) => void;
  enterprises?: FilterCurrentSelection;
  companies?: FilterCurrentSelection;
}

function NativeDossierContainer(props: NativeDossierContainerProp) {
  return (
    <NativeDossier
      nativeVisualsDossiers={props.nativeVisualsDossiers}
      onDossierRefreshed={props.onDossierRefreshed}
      enterprises={props.enterprises}
      companies={props.companies}
    ></NativeDossier>
  );
}

export default NativeDossierContainer;
